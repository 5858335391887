@import 'abstracts';

body {
  .p-checkbox .p-checkbox-box.p-highlight,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus,
  .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover,
  .p-checkbox .p-checkbox-box.p-highlight.p-focus:not(.p-disabled):hover {
    background: var(--cap-primary-color);
    box-shadow: inset 0 0 0 2px $white;

    .p-checkbox-icon {
      display: none;
    }
  }

  cap-checkbox-filter {
    .cap-dynamic-filter-layout-content {
      cap-checkbox {
        cap-validator {
          display: none;
        }
      }
    }
  }
}
