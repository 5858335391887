@import 'abstracts';

.p-dialog.p-confirm-dialog {
  border-radius: 5px;
  background-color: $white;
  padding: 1em;
  min-width: 425px;

  .p-dialog-header {
    display: flex;
    width: 100%;
    justify-content: end;

    .p-dialog-title {
      font-family: $font-semi-bold;
      margin-right: 28%;
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .p-dialog-content {
    align-self: center;
  }

  .p-dialog-titlebar,
  .p-dialog-content,
  .p-dialog-footer {
    border: none;
    border-radius: inherit;
    color: var(--cap-primary-color);
    padding: 1em;

    p-footer {
      @include flexCentering();

      /* Yes/No buttons tend to be kind of small */
      .cap-button {
        min-width: 6em;
      }
    }
  }

  .p-dialog-titlebar {
    @include straightBottom();
    font-family: $font-semi-bold;

    .p-dialog-title {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }

  .p-dialog-content {
    .p-confirm-dialog-message {
      width: 100%;
      color: var(--primary-text);
    }
  }
}

div.p-overlaypanel {
  border-radius: 3px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
  border: none;

  .p-overlaypanel-content {
    padding: 0;
  }
}

@include media-breakpoint-down(md) {
  .p-dialog.p-confirm-dialog {
    min-width: 500px;

    .p-dialog-header {
      .p-dialog-title {
        font-size: 2rem;
      }

      .p-dialog-header-icons {
        span.pi {
          font-size: 1.3rem !important;
        }
      }
    }

    .p-dialog-footer {
      p-footer {
        .cap-button {
          font-size: 1.6rem;
        }
      }
    }

    .p-dialog-content {
      .p-confirm-dialog-message {
        font-size: 1.3rem;
      }
    }
  }
}

@include media-breakpoint-down(ыь) {
  .p-dialog.p-confirm-dialog {
    min-width: 350px;
  }
}
