@import 'abstracts';

.tab {
  &__header {
    padding-bottom: 20px;
    align-items: center;
  }

  &__heading {
    font-size: 20px;
    font-weight: 600;
    color: $liver;
    margin: auto;
  }

  &__actions {
    text-align: right;
    margin-top: -10px;
    @include setLeftGutters(20px);

    .cap-button {
      margin-top: 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  .tab {
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      cap-button,
      .p-button.cap-button {
        width: 100%;
      }

      * {
        margin: 0;
      }
    }
  }
}
