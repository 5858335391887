@import 'abstracts';

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $gray-light;
  border-radius: 6.5px;
}

::-webkit-scrollbar-thumb {
  background: $platinum;
  border-radius: 6.5px;
}

::-webkit-scrollbar-thumb:hover {
  background: $input-border-color;
}
