cap-segmented-buttons {
  ul.component {
    border: 1px solid var(--cap-primary-color);
    border-radius: 4px;
    width: max-content;

    @include media-breakpoint-down(sm) {
      display: block !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */
      width: 100%;
    }

    li {
      border-right: 1px solid var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */
      color: var(--cap-primary-color);
      padding-left: 15px !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */
      padding-right: 15px !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */

      &:last-child {
        border: none;
        border-right: 0 !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */
      }

      &.active {
        background-color: var(--cap-primary-color);
        color: $white;
      }
    }
  }
}
