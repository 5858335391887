@import 'abstracts';

.location {
  display: inline-block;
  text-align: left;

  &__text {
    font-size: 14px;
    font-weight: 600;
    color: $sidebar-text;
  }

  &__dropdown {
    width: 250px;
    margin-left: 20px;
  }

  &__selected-item {
    i {
      margin-right: 5px;
      font-size: 16px;
      color: $liver;
    }
  }
}
