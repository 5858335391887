@import 'abstracts';

@font-face {
  font-family: $font-light;
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/open-sans.light.ttf');
}

@font-face {
  font-family: $font-regular;
  font-style: normal;
  font-weight: normal;
  src: url('../../assets/fonts/open-sans.regular.ttf');
}

@font-face {
  font-family: $font-semi-bold;
  font-style: normal;
  font-weight: 600;
  src: url('../../assets/fonts/open-sans.semibold.ttf');
}

@font-face {
  font-family: $font-bold;
  font-style: normal;
  font-weight: bold;
  src: url('../../assets/fonts/open-sans.bold.ttf');
}
