/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts';
@import './base/colors';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import 'components/buttons';
@import 'components/confirm-dialogs';
@import 'components/list-render';
@import 'components/dynamic-dialog';
@import 'components/tab-view';
@import 'components/scroll-bar';
@import 'components/tooltip.scss';
@import 'components/info-tables.scss';
@import 'components/sidebar.scss';
@import 'components/paginator';
@import 'components/panel';
@import 'components/form-renderer';
@import 'components/checkboxes';
@import 'components/segmented-button';
@import 'components/toast';
@import 'components/split-button';

/* Layouts */
@import './layouts/page';
@import './layouts/tab';
@import './layouts/custom-forms';
