@import 'abstracts';

.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon:before,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover .p-checkbox-icon:before {
  color: var(--cap-primary-color);
}

.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight,
.cap-info-table.p-datatable .p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: var(--cap-primary-color);
}

cap-info-table {
  .p-datatable-scrollable .p-datatable-thead, .p-datatable-scrollable .p-datatable-tbody, .p-datatable-scrollable .p-datatable-tfoot {
    display: contents;
  }
}
