/* You can add global styles to this file, and also import other style files */
// prettier-ignore
@import './styles/main.scss';

:root {
  --text-color-4: #fff;
}

html,
body {
  height: 100%;
}

button:focus {
  outline: none;
}

app-page-layout-header.purchase-order-page-layout-header {
  .page__actions {
    display: flex;
    flex-direction: row-reverse;

    cap-action-menu {
      margin-left: 10px;
    }
  }
}
