@import 'abstracts';

body .p-button.cap-button {
  &__gray {
    background-color: $filter-gray;
    outline: none;
    border: none;
    border-radius: 16.5px;
    height: 33px;
    font-size: 15px;
    font-weight: 600;
  }

  &__cancel {
    border-radius: 3px;
    border: 2px solid var(--cap-primary-color);
    box-shadow: 0 2px 4px 0 rgb(211 0 35 / 15%);
    height: 40px;
    background-color: $white;
    color: var(--cap-primary-color);
    font-size: 14px;
    font-weight: 600;
  }

  &__primary {
    border-radius: 3px;
    border: none;
    box-shadow: 0 2px 4px 0 rgb(211 0 35 / 15%);
    height: 40px;
    background-color: var(--cap-primary-color);
    color: $white;
    font-size: 14px;
    font-weight: 600;
  }

  &__submit {
    background: $cap-success;
    border-radius: 3px;
    border: 2px solid $cap-success;
    box-shadow: 0 2px 4px 0 rgba(53, 196, 77, 0.15);
    height: 40px;
    color: $white;
    font-size: 14px;
    font-weight: 600;
  }
}

.p-button-icon-left {
  margin-right: 0.75rem;

  .fas.fa-spinner {
    padding-right: 0;
    margin-right: 1rem;
  }
}

cap-button p-button .p-button.cap-button.p-button-icon-only {
  padding: 10px 20px;

  .p-button-label {
    display: none;
  }
}

body .p-button .p-button-label {
  text-decoration: none !important; /* stylelint-disable-line declaration-no-important */
}

.cap-button.primary,
.cap-button.secondary,
.cap-button.tertiary,
.cap-button.tertiary:not(:disabled),
.cap-button.tertiary:enabled,
.cap-button.round,
.cap-button.success,
.cap-button__gray,
.cap-button__primary,
.cap-button__cancel,
.cap-button__submit,
.menu-action__button-icon {
  &:hover {
    filter: brightness(0.9);
    transition: filter 0.3s ease-in-out;
    text-decoration: none;
  }
}

.menu-cta {
  &:hover {
    background-color: $filter-gray;
    transition: background-color 0.3s ease-in-out;
  }
}

.p-splitbutton:hover {
  .p-splitbutton-defaultbutton,
  .p-splitbutton-menubutton {
    filter: brightness(0.9);
    transition: filter 0.3s ease-in-out;
  }
}

.cap-button {
  &.round,
  &.tertiary {
    color: $gray-dark !important; /* stylelint-disable-line declaration-no-important */
  }
}


body cap-input-number {
  &.show-buttons {
    .p-inputgroup {
      display: flex;
      justify-content: center;

      .p-inputwrapper {
        flex: 0;
        width: auto;
      }
    }

    p-inputnumber {
      .p-inputnumber.p-component {
        column-gap: 0.2rem;

        .p-inputnumber-input,
        .p-inputnumber-input.p-inputtext:enabled:hover:not(.p-state-error),
        .p-inputnumber-input.p-inputtext:enabled:focus:not(.p-state-error) {
          flex-grow: initial;
          width: 5rem;
          flex-basis: 5rem;
          text-align: center;
          pointer-events: none;
          user-select: none;
          border: none;
          box-shadow: 0px 1px 7px 1px rgba($black, .1);
        }
      }
    }
  }
}
