@import 'abstracts';

$border-radius: 6px;

body {
  p-tabview .p-tabview {
    .p-tabview-panels {
      border: 1px solid $border-gray;
      border-top: none;
      background-color: $white;
      border-bottom-right-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
      padding: 20px;
    }

    .p-tabview-nav {
      border-bottom: 1px solid $border-gray;

      li.p-highlight {
        background: $white;
        margin-bottom: -1px;
        border: 1px solid $border-gray;
        border-bottom: none;
      }
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight a.p-tabview-nav-link {
    color: $gray-dark;
  }

  .p-tabview-title {
    font-size: 16px;
  }
}

@include media-breakpoint-down(md) {
  body p-tabview .p-tabview {
    .p-tabview-panels {
      border: 1px solid $border-gray;
      border-radius: $border-radius;
      padding: 12px;
    }
    .p-tabview-nav {
      border: none;
    }
  }
}
