/* General colors */
$primary: #d50232;
$primary-lighter: #cd1719;
$primary-light: #f7fcfe;
$liver: #4f5152;

/* Company colors */
$cashcarry: #d30023;
$gemotra: #ecab02;
$hermes: #7d52d9;
$export: #458a49;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #5f6062;
$white: #fff;

$cap-input-height: 33px;

/* General sizes */
$nav-height: 75px;
$mobile-nav-height: 50px;
$page-gutters: 44px;
$mobile-page-gutters: 20px;

/* General */
$gray-light: #f9f9f9;
$gray-dark: #333;
$body-color: #5f6062;
$liver: #4c4c4c;
$platinum: #e6e6e6;
$gray-medium: #a4a4a4;
$filter-gray: #e2e2e2ff;
$border-gray: #e7eaec;
$header-gray: #f7f7f7;
$sidebar-text: #7a7a7a;
$column-text-color: #3b3b3b;
$filter-font-color: #b5b5b5;
$border-color: #ddd;

$cap-input-font: 14px;

$separator-color: #c8c8c8;
$table-row-border: #f4f4f4;
$bulk-action-border: #68e76f;
$focused-input-border: #1C6ECB;
$gray-thead-color: #7a7a7a;

$filter-icon-color: #616062;
$filter-height: 34px;

$alert-icon-color: #ff9500;
