@import 'abstracts';

body {
  cap-sidebar-menu {
    .cap-sidebar {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 100vh;
      position: unset;
      transition: 0.40s all;
    }
  }

  .cap-sidebar__menus li {
    padding: 18px;
    color: $sidebar-text;
    font-size: 16px;
    font-weight: 600;

    &:hover,
    &.active {
      background-color: rgba(205, 23, 25, 0.03);
    }
  }

  .cap-sidebar__brand {
    padding: 25px 0 55px;
  }
}
