@import 'abstracts';

.custom-forms {
  $label-size: 150px;
  $label-margin: 10px;

  &__wrapper {
    @include setBottomGutters(10px);
    max-height: 650px;
    height: fit-content;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: 20px;

    ::ng-deep .cpb-grid {
      margin: unset;
    }

    ::ng-deep cpb-form-renderer formly-form formly-field formly-group > formly-field {
      border-right: 1px solid $border-gray;
      padding: 15px 25px;
      overflow: hidden;
    }

    ::ng-deep cpb-form-renderer formly-form formly-field formly-group > formly-field:last-child {
      border-right: none;
    }

    ::ng-deep cpb-form-renderer formly-form formly-field formly-group > formly-field:first-child {
      padding-left: 0;
    }

    cpb-list-renderer {
      max-width: 708px;
      display: block;
    }
  }

  &__buttons {
    display: flex;
    padding: 30px 0;
    align-items: center;
    margin-left: 10px;
    justify-content: space-between;
    border-top: 1px solid $cap-gray;
    position: sticky;
  }

  .field {
    display: flex;
    align-items: center;

    &__label {
      width: $label-size;
      font-size: 16px;
      font-weight: 600;
      margin-right: $label-margin;
      margin-top: -20px; // To align correct because of cap-validator
    }

    &__value {
      width: calc(100% - #{$label-size + $label-margin});
    }
  }

  .form-group cpb-readonly-container div.readonly-container {
    height: 38px;
    border: 1px solid $cap-gray;
    border-radius: 3px;
    background: rgb(249, 249, 249);
    padding: 0.429em;
    margin-top: 0;
    font-size: 14px;
  }

  cpb-form-renderer div.form-group {
    align-items: center;
  }

  .p-inputgroup input.p-inputtext:disabled,
  .p-inputgroup input.p-inputtext[disabled] {
    background: rgb(249, 249, 249);
    opacity: 1;
  }
}
