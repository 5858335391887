@import 'abstracts';

body .p-tooltip {
  box-shadow: $black;
  border-radius: 2px;
  padding: 5px;

  &:not(.cap-sidebar-tooltip) {
    background-color: $white;
  }

  .p-tooltip-text {
    background-color: $white;
    color: $gray-dark;
  }

  &.p-tooltip-top,
  .p-tooltip-arrow {
    color: $white;
  }

  &.p-tooltip-left .p-tooltip-arrow {
    color: $white;
  }

  &.cap-sidebar-tooltip {
    .p-tooltip-arrow {
      border-right-color: var(--cap-primary-color);
      opacity: 0.5;
    }
  }
}
