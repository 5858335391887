@import 'abstracts';

/* ut = Utility */
.ut-primary-text {
  color: var(--cap-primary-color);
}

.ut-error-text {
  color: $red;
}

/* Util color Error */
.ut-color-error {
  color: $red;
}

/* Util color Success */
.ut-color-success {
  color: $success;
}

/* Util color Warning */
.ut-color-warning {
  color: $orange;
}
