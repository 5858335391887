@import 'abstracts';

body .cap-toast {
  z-index: 2121 !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */

  @include media-breakpoint-down(sm) {
    width: calc(100% - 40px) !important; /* stylelint-disable-line declaration-no-important */ /* stylelint-disable-line declaration-no-important */
  }

  @media screen and (max-width: 450px) {
    .p-toast-message-success {
      display: none;
    }
  }
}
