@import 'abstracts';
@import './includes/location-dropdown';

.panel-content {
  padding: 20px;
  @include setBottomGutters(15px);

  .list-wrapper {
    max-height: 100%;
    overflow: hidden;
    width: 100%;
  }

  &.row-2 .list-wrapper {
    max-height: 100%;
  }

  ::ng-deep {
    cap-validator {
      display: none;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    color: $liver;
  }
}

app-panel {
  height: 100vh;
  display: flex;

  .panel {
    height: 100%;
    display: flex;
    flex-direction: column;

    .panel__wrapper {
      flex: 1;
    }
  }
}

app-day-price-panel,
app-negative-stock-panel {
  .panel-content {
    app-list-renderer-widget-text-style-by-condition {
      display: flex;
      div.p-element {
        margin-left: 2px;
      }

      .cap-cell-tooltip {
        line-height: 1 !important; /* stylelint-disable-line declaration-no-important */
      }
    }
  }
}
